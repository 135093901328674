import FooterCTA from "@modules/layout/components/footer-cta"
import FooterNav from "@modules/layout/components/footer-nav"

const Footer = () => {
    return (
        <footer className={"bg-darkTone text-neutral-50"}>
            {/*<FooterCTA />*/}
            <FooterNav />
        </footer>
    )
}

export default Footer
