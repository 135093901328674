import { ReviewResponse } from "@lib/data/_types"
import { MEDUSA_BACKEND_URL } from "@lib/config"

const fetchReviews = async (id: string): Promise<ReviewResponse> => {
    return fetch(`${MEDUSA_BACKEND_URL}store/products/${id}/reviews`).then(
        (res) => res.json()
    )
}

export default fetchReviews
