import clsx from "clsx"
import Link from "next/link"
import { ProductPreviewType } from "types/global"
import Thumbnail from "../thumbnail"
import { useStore } from "@lib/context/store-context"
import React from "react"
import { MdOutlineShoppingCart } from "react-icons/md"
import { HiPlusSm } from "react-icons/hi"
import { useQuery } from "react-query"
import fetchReviews from "@lib/util/fetchReviews"
import { Review } from "@lib/data/_types"
import { useCartDropdown } from "@lib/context/cart-dropdown-context"

const Stars = ({
    loading,
    data
}: {
    loading: boolean
    data: Array<Review>
}) => {
    const reviewRate = data.reduce((a, b) => a + b.rating, 0) / data.length
    return (
        <>
            {loading ? (
                <div className="flex flex-row items-center justify-start">
                    <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse mr-2"></div>
                    <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse mr-2"></div>
                    <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse mr-2"></div>
                    <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse mr-2"></div>
                    <div className="w-4 h-4 bg-gray-200 rounded-full animate-pulse mr-2"></div>
                </div>
            ) : (
                reviewRate > 0 && (
                    <div className={"flex flex-row items-center justify-start"}>
                        {[...Array(Math.ceil(reviewRate))].map((_, i) => (
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-yellow-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Review star</title>
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                        ))}
                        {[...Array(5 - Math.ceil(reviewRate))].map((_, i) => (
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-gray-200"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Review star</title>
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                        ))}{" "}
                        <span className={"ml-1 text-xs"}>( {reviewRate} )</span>
                    </div>
                )
            )}
        </>
    )
}

const ProductPreview = ({
    id,
    title,
    handle,
    thumbnail,
    secondThumbnail,
    deliveryDate,
    presale,
    inStock,
    price,
    variant,
    doubleColl = false,
    newStyle = false
}: ProductPreviewType) => {
    const { setProduct, setProductId } = useCartDropdown()
    const { addItem } = useStore()
    const addToCart = () => {
        if (typeof variant !== "undefined") {
            setProduct(title)
            setProductId(id)
            addItem({
                variantId: variant,
                quantity: 1
            })
        }
    }
    const reviewData = useQuery(
        [`get_review`, handle, `reviews`],
        () => fetchReviews(id),
        {
            enabled: (handle ?? "").length > 0,
            keepPreviousData: true
        }
    )
    const reviews = reviewData.data?.product_reviews ?? []
    deliveryDate = deliveryDate ? new Date(deliveryDate) : undefined
    return (
        <Link href={`/producten/${handle}`}>
            <a className={clsx({ "flex flex-col h-full": newStyle })}>
                <div
                    className={clsx(
                        {
                            "relative second-thumbnail overflow-hidden":
                                secondThumbnail
                        },
                        { "h-full flex flex-col": newStyle }
                    )}
                >
                    <Thumbnail
                        thumbnail={thumbnail}
                        objectFit={"cover"}
                        containerClassName={clsx(
                            "relative first-thumbnail overflow-hidden",
                            { "aspect-square md:aspect-[2/1]": doubleColl },
                            { "aspect-square": !doubleColl }
                        )}
                        size="full"
                        sale={price && price.price_type === "sale"}
                    />
                    {secondThumbnail && (
                        <Thumbnail
                            containerClassName={clsx(
                                "absolute second-thumbnail__img top-0 z-10 opacity-0 hover:opacity-100 transition-all duration-500 ease-in-out",
                                { "aspect-[2/1]": doubleColl },
                                { "aspect-square": !doubleColl }
                            )}
                            thumbnail={secondThumbnail}
                            size="full"
                        />
                    )}
                    <div
                        className={clsx(
                            "text-base-regular mt-2 text-slate-700",
                            {
                                "font-semibold flex flex-col flex-grow":
                                    newStyle
                            }
                        )}
                    >
                        <h4
                            className={clsx(
                                "font-mono inline-block w-full",
                                {
                                    " text-lg overflow-hidden whitespace-nowrap text-ellipsis product-h4-title":
                                        !newStyle
                                },
                                {
                                    "text-sm xl:text-md 2xlarge:text-lg":
                                        newStyle
                                }
                            )}
                        >
                            <span>{title}</span>
                        </h4>
                        <Stars loading={reviewData.isLoading} data={reviews} />
                        {!newStyle && (
                            <>
                                {presale ? (
                                    <span className="text-green-500 font-semibold">
                                        Pre-order
                                        <br />
                                        {deliveryDate &&
                                            `verwachte levertijd ${deliveryDate.getDate()} ${deliveryDate.toLocaleString(
                                                "nl-NL",
                                                {
                                                    month: "long"
                                                }
                                            )} ${deliveryDate.getFullYear()}`}
                                    </span>
                                ) : inStock ? (
                                    <span className="text-green-500 font-semibold">
                                        Op voorraad
                                    </span>
                                ) : (
                                    <span className="text-red-500 font-normal">
                                        Niet op voorraad
                                    </span>
                                )}
                            </>
                        )}
                        <div
                            className={clsx(
                                "flex text-base gap-x-2",
                                { "items-center mt-1": !newStyle },
                                { "flex-col items-start mt-[auto]": newStyle }
                            )}
                        >
                            {price ? (
                                <>
                                    {price.price_type === "sale" && (
                                        <span className="line-through text-slate-800">
                                            {price.original_price}
                                        </span>
                                    )}
                                    <span
                                        className={clsx(
                                            "font-semibold text-rose-500"
                                        )}
                                    >
                                        {price.calculated_price}
                                    </span>
                                </>
                            ) : (
                                <div className="w-20 h-6 animate-pulse bg-gray-900"></div>
                            )}
                        </div>
                        {newStyle && (
                            <button
                                aria-label={"Voeg toe aan winkelwagen"}
                                className={clsx(
                                    "flex items-center justify-center min-h-[40px] px-2 py-1 transition-colors duration-200 disabled:opacity-50 disabled:hover:bg-gray-900 disabled:hover:text-white",
                                    "mt-5 bg-yellow-400 text-slate-900 hover:bg-yellow-300 border border-slate-800 rounded place-self-start"
                                )}
                                onClick={addToCart}
                            >
                                <HiPlusSm size={24} />
                                <MdOutlineShoppingCart size={24} />
                            </button>
                        )}
                    </div>
                </div>
            </a>
        </Link>
    )
}

export default ProductPreview
