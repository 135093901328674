type ImageLoaderProps = {
    src: string
    width: number
    quality?: number
}
export const ImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
    src = src
        .replace("https://akupanelstore.ams3.digitaloceanspaces.com/", "")
        .replace("https://ik.imagekit.io/ik8qpq4jb/", "")
        .replace("https://ik.imagekit.io/mc4lho04c/", "")
        .replace("ams3.digitaloceanspaces.com/akupanelstore/", "")
    if (src[0] === "/") src = src.slice(1)
    const params = [
        `width=${Math.min(1920, width)}`,
        "format=webp",
        "lossless=true",
        "watermark=logo_long.png?format=png&watermark-alpha=10&watermark-position=center%2Cmiddle&watermark-scale=70"
    ]
    if (quality) params.push(`q-${quality}`)
    const paramsString = params.join("&")
    let urlEndpoint = "https://akupanelcdn.imglab-cdn.net"
    if (urlEndpoint[urlEndpoint.length - 1] === "/")
        urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1)
    return `${urlEndpoint}/${src}?${paramsString}`
}
