import { string } from "prop-types"

export const contentURL =
    process.env.NEXT_PUBLIC_STRAPI_URL || "http://localhost:1337/"
export const shortPopulateString = "populate[0]=seo&populate[1]=seo.metaImage"
export const populateString =
    "populate[0]=content&populate[1]=content.image&populate[2]=content.image2&populate[3]=content.cta&populate[4]=seo&populate[5]=seo.metaImage&populate[6]=seo.metaSocial&populate[7]=seo.metaSocial.image&populate[8]=content.firstImage&populate[9]=content.secondImage&populate[10]=content.accordion&populate[11]=content.bannerImage&populate[12]=content.Blocks&populate[13]=content.Blocks.backgroundImage&populate[14]=content.Blocks.cta&populate[15]=content.product_collections&populate[16]=content.products&populate[17]=content.items&populate[18]=usps"

export const getSiteReviews = async (): Promise<Array<SiteReview>> => {
    const res = await fetch(
        `${contentURL}api/google-reviews?filters[visible][$eq]=true`
    )
    const data = await res.json()
    return data?.data.map((o: any) => o?.attributes) as Array<SiteReview>
}

export const parseContent = (item: {
    [key: string]: any
}):
    | HeroProp
    | OneImageProp
    | TwoImageProp
    | FullTextProp
    | BannerProp
    | BlocksProp
    | ProductsProp
    | SliderProp
    | UspProp
    | ReviewsProp => {
    switch (item["__component"]) {
        case "shared.hero":
            const heroSection: HeroProp = {
                id: item.id,
                type: "hero",
                title: item.title ?? item.Title,
                subTitle: item.subTitle,
                titleType: item.titleType ?? item.titletype ?? "h2",
                content: item.content ?? "",
                image: parseImage(item.firstImage),
                image2: parseImage(item.secondImage)
            }
            if (item?.cta && item?.cta?.ctaText) heroSection.cta = item.cta
            return heroSection
            break
        case "shared.blocks":
            const blockSection: BlocksProp = {
                id: item.id,
                type: "blocks",
                amountPerRow: item.amountPerRow ?? 3,
                blocks: item?.Blocks.map((block: { [key: string]: any }) => {
                    const data: BlocksContentProp = {
                        id: block.id,
                        title: block.title ?? "",
                        cta: block?.cta ?? null,
                        wholeClick: block.wholeClick ?? false
                    }
                    if (block?.backgroundImage?.data)
                        data.image = parseImage(block.backgroundImage)
                    return data
                })
            }
            //if (item?.cta && item?.cta?.ctaText) blockSection.cta = item.cta
            return blockSection
            break
        case "shared.two-image":
            const twoImage: TwoImageProp = {
                id: item.id,
                type: "two-image",
                title: item.title,
                titleType: item.titleType ?? item.titletype ?? "h2",
                content: item.content,
                accordion: item.accordion ?? [],
                faqitem: item?.faqitem ?? false,
                image: parseImage(item.image),
                image2: parseImage(item.image2),
                imageLocation: item.imageLocation,
                titleVertical: item?.titleVertical ?? "top"
            }
            if (item?.cta && item?.cta?.ctaText) twoImage.cta = item.cta
            return twoImage
            break
        case "shared.one-image":
            const oneImage: OneImageProp = {
                id: item.id,
                type: "one-image",
                title: item.title,
                titleType: item.titleType ?? item.titletype ?? "h2",
                content: item.content,
                accordion: item.accordion ?? [],
                faqitem: item?.faqitem ?? false,
                image: parseImage(item.image),
                imageLocation: item.imageLocation,
                titleVertical: item?.titleVertical ?? "top"
            }
            if (item?.cta && item?.cta?.ctaText) oneImage.cta = item.cta
            return oneImage
            break
        case "shared.banner":
            const banner: BannerProp = {
                id: item.id,
                type: "banner",
                title: item.title,
                content: item.description,
                image: parseImage(item.bannerImage)
            }
            if (item?.cta && item?.cta?.ctaText) banner.cta = item.cta
            return banner
            break
        case "shared.reviews":
            const reviews: ReviewsProp = {
                id: item.id,
                type: "reviews",
                title: item.title,
                show_title: item.show_title
            }
            return reviews
            break
        default:
        case "shared.full-text":
            const fullText: FullTextProp = {
                id: item.id,
                type: "full-text",
                title: item.title,
                titleType: item.titleType ?? item.titletype ?? "h2",
                content: item.content,
                accordion: item.accordion ?? [],
                faqitem: item?.faqitem ?? false,
                layout: item.layout,
                titleVertical: item?.titleVertical ?? "top"
            }
            if (item?.cta && item?.cta?.ctaText) fullText.cta = item.cta
            return fullText
            break
        case "shared.product-grid":
            const products: ProductsProp = {
                id: item.id,
                type: "productsgrid",
                title: "rtest",
                limit: item.limit,
                sort: item.sort,
                exclude: item.exclude,
                collections:
                    item.product_collections?.data
                        ?.map(
                            (pdata: any) => pdata?.attributes?.medusa_id ?? null
                        )
                        ?.filter((handle: string | null) => handle !== null) ??
                    [],
                products:
                    item.products?.data
                        ?.map(
                            (pdata: any) => pdata?.attributes?.medusa_id ?? null
                        )
                        ?.filter((handle: string | null) => handle !== null) ??
                    []
            }
            return products
            break
        case "shared.slider":
            const slider: SliderProp = {
                id: item.id,
                type: "slider",
                title: item?.title ?? "",
                products:
                    item.products?.data
                        ?.map(
                            (pdata: any) => pdata?.attributes?.medusa_id ?? null
                        )
                        ?.filter((handle: string | null) => handle !== null) ??
                    []
            }
            if (item?.cta && item?.cta?.ctaText) slider.cta = item.cta
            return slider
            break
        case "shared.usp-block":
            const uspBlock: UspProp = {
                id: item.id,
                type: "uspblock",
                style: item.style
            }
            if (item?.items) uspBlock.items = item.items
            return uspBlock
    }
}
export const parseImage = (_image: {
    [key: string]: any
}): undefined | Image => {
    if (!_image || typeof _image.data === "undefined") return undefined
    const image = _image.data
    const sizes: {
        [key: string]: { [key: string]: string | number | null }
    } = image?.attributes?.formats || {}
    const response: Image = {
        id: parseInt(image.id),
        name: image?.attributes?.name,
        width: image?.attributes.width,
        height: image?.attributes.height,
        url: image?.attributes.url,
        sizes: null
    }
    if (image?.attributes?.alternativeText)
        response.alternativeText = image?.attributes?.alternativeText
    if (image?.attributes?.caption)
        response.caption = image?.attributes?.caption
    if (Object.keys(sizes).length > 0) {
        response.sizes = Object.fromEntries(
            Object.entries(sizes).map(([k, v], i) => [
                k,
                {
                    url: v?.url,
                    width: v?.width,
                    height: v?.height
                }
            ])
        ) as ImageSize
    }
    return response
}

export type CTAProp = {
    id: number
    ctaText: string
    ctaUrl: string
    ctaPosition?:
        | "under title"
        | "under text left"
        | "under text right"
        | "under text 100%"
}

export type AccordionProp = {
    id: number
    title: string
    content: string
    initialPosition: boolean
}

export type FullTextProp = {
    id: number
    type: "full-text"
    title: string
    titleType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
    titleVertical?: "top" | "middle" | "bottom"
    content: string
    accordion: Array<AccordionProp>
    faqitem?: boolean
    layout: "title left" | "title right" | "title top"
    cta?: CTAProp
}

export type OneImageProp = {
    id: number
    type: "one-image"
    title: string
    titleType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
    titleVertical?: "top" | "middle" | "bottom"
    content: string
    accordion: Array<AccordionProp>
    faqitem?: boolean
    image?: Image
    imageLocation: "left 1/3" | "left 3/5" | "right 1/3" | "right 3/5"
    cta?: CTAProp
}

export type BannerProp = {
    id: number
    type: "banner"
    titleVertical?: "top" | "middle" | "bottom"
    title: string
    content: string
    image?: Image
    cta?: CTAProp
}

export type TwoImageProp = {
    id: number
    type: "two-image"
    title: string
    titleType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
    titleVertical?: "top" | "middle" | "bottom"
    content: string
    accordion: Array<AccordionProp>
    faqitem?: boolean
    image?: Image
    image2?: Image
    imageLocation: "left top" | "left bottom" | "right top" | "right bottom"
    cta?: CTAProp
}

export type HeroProp = {
    id: number
    type: "hero"
    title: string
    titleType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
    subTitle: string
    content: string
    reviews?: Review[]
    image?: Image
    image2?: Image
    cta?: CTAProp
}

export type BlocksContentProp = {
    id: number
    image?: Image
    title: string
    cta?: CTAProp
    wholeClick: boolean
}

export type BlocksProp = {
    id: number
    type: "blocks"
    amountPerRow: number
    blocks?: BlocksContentProp[]
}

export type ProductsProp = {
    id: number
    type: "productsgrid"
    limit: number
    sort: "price:asc" | "price:desc" | "title:asc" | "title:desc" | "random"
    title: string
    exclude: boolean
    collections: string[]
    products: string[]
}

export type UspProp = {
    id: number
    type: "uspblock"
    style: string
    cta?: CTAProp
    items?: Array<{
        id: number
        Order: number
        content: string
    }>
}

export type SliderProp = {
    id: number
    type: "slider"
    title: string
    products: string[]
    cta?: CTAProp
}

export type ReviewsProp = {
    id: number
    type: "reviews"
    title: string
    show_title: boolean
}

export type metaSocialProp = {
    id: number
    socialNetwork: string
    title: string
    description: string
    image?: Image
}

export type seoProp = {
    id: number
    metaTitle: string
    metaDescription: string
    metaImage?: Image
    keywords: string
    metaRobots: "index, follow" | "noindex, nofollow" | "index, nofollow"
    structuredData: string | null
    metaViewport: string
    canonicalURL: string
    metaSocial: Array<metaSocialProp>
}

export type Image = {
    id: number
    name: string
    alternativeText?: string
    caption?: string
    width: number
    height: number
    url: string
    sizes?: ImageSize | null
}

export type ImageSize = {
    [key: string]: {
        url: string
        width: number
        height: number
    }
}

export type Review = {
    id: string
    title: string
    full_name: string
    rating: number
    content: string
    created_at: string
}

export type SiteReview = {
    fullName: string
    stars: number
    review: string
    visible: boolean
    createdAt: string
    updatedAt: string
    publishedAt: string
}

export type ReviewResponse = {
    product_reviews: Array<Review>
}
