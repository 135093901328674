import { Popover, Transition } from "@headlessui/react"
import { useCartDropdown } from "@lib/context/cart-dropdown-context"
import useEnrichedLineItems from "@lib/hooks/use-enrich-line-items"
import { useCart } from "medusa-react"
import Link from "next/link"
import { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { ShopCart, ShoppingCartHouse } from "@vectopus/atlas-icons-react"
import dynamic from "next/dynamic"

const DynamicCartDropdownContent = dynamic(
    () => import("../cart-dropdown-content"),
    { loading: () => <p>Loading...</p> }
)

const CartDropdown = () => {
    const { cart, totalItems } = useCart()
    const { state, open, close } = useCartDropdown()

    return (
        <div className="h-full z-50" onMouseEnter={open} onMouseLeave={close}>
            <Popover className="relative h-full">
                <Link href="/winkelwagen" passHref prefetch={false}>
                    <Popover.Button
                        aria-label="Winkelwagen"
                        className="h-full text-small lg:text-base font-mono relative"
                    >
                        <span className={"hidden"}>
                            <FormattedMessage
                                id={"cart-title"}
                                defaultMessage={"Winkelwagen"}
                            />
                        </span>
                        {(totalItems ?? 0) > 0 ? (
                            <ShoppingCartHouse size={25} weight="thin" />
                        ) : (
                            <ShopCart size={25} weight="thin" />
                        )}
                        {totalItems > 0 && (
                            <span
                                className={
                                    "font-sans ml-1 text-xs rounded-full w-[18px] h-[18px] -right-[10px] -mt-[10px] absolute inline-block align-middle text-white bg-brand pt-[1px]"
                                }
                            >
                                {totalItems}
                            </span>
                        )}
                    </Popover.Button>
                </Link>
                <Transition
                    show={state}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel
                        static
                        className="hidden small:block absolute top-[calc(100%+1px)] right-0 bg-white border-x border-b border-gray-200 w-[382px] text-gray-900"
                    >
                        <DynamicCartDropdownContent cart={cart} />
                    </Popover.Panel>
                </Transition>
            </Popover>
        </div>
    )
}

export default CartDropdown
