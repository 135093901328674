import {
    BannerProp,
    BlocksProp,
    contentURL,
    FullTextProp,
    HeroProp,
    OneImageProp,
    parseContent,
    parseImage,
    populateString,
    ProductsProp,
    ReviewsProp,
    seoProp,
    shortPopulateString,
    SliderProp,
    TwoImageProp,
    UspProp
} from "./_types"

export type post = {
    id: number
    title: string
    slug: string
    updatedAt: string
    publishedAt: string
    createdAt: string
    content?: Array<
        | HeroProp
        | OneImageProp
        | TwoImageProp
        | FullTextProp
        | BannerProp
        | BlocksProp
        | ProductsProp
        | UspProp
        | SliderProp
        | ReviewsProp
    >
    seo?: seoProp
    usps?: Array<{
        id: number
        Order: number
        content: string
    }>
}

export type posts = {
    posts: post[]
    page: number
    pageSize: number
    pageCount: number
    total: number
}

export type website = {
    items: Array<{
        id: number
        Order: number
        content: string
    }>
    desktopitems: Array<{
        id: number
        Order: number
        content: string
    }>
    uspbanner: string
    uspbannerdesktop: string
    createdAt: string
}
const parsePost = (post: {
    id: number
    attributes: { [key: string]: any }
}): post => {
    const { id, attributes } = post
    const postData: post = {
        id,
        slug: attributes?.slug ?? attributes?.Slug ?? attributes?.handle,
        title: attributes?.title ?? attributes?.Title ?? "",
        updatedAt: attributes?.updatedAt ?? new Date().toISOString(),
        publishedAt: attributes?.publishedAt ?? new Date().toISOString(),
        createdAt: attributes?.createdAt ?? new Date().toISOString()
    }
    if (attributes?.seo) {
        postData.seo = attributes.seo as seoProp
        postData.seo.metaImage = parseImage(attributes.seo.metaImage)
        if (postData.seo.metaSocial) {
            postData.seo.metaSocial = postData.seo.metaSocial.map(
                (item: { [key: string]: any }) => {
                    return {
                        id: item.id,
                        socialNetwork: item.socialNetwork,
                        title: item.title,
                        description: item.description,
                        image: parseImage(item.image)
                    }
                }
            )
        }
    }
    if (attributes?.content) {
        postData.content = attributes.content.map(
            (item: { [key: string]: any }) => parseContent(item)
        )
    }
    if (attributes?.usps) {
        postData.usps = attributes?.usps
    }
    return postData as post
}

export async function getPosts(slug = "inspirations"): Promise<posts> {
    //console.log(`${contentURL}api/${slug}?${shortPopulateString}`)
    return fetch(`${contentURL}api/${slug}?${shortPopulateString}`)
        .then((res) => res.json())
        .then((data) => ({
            posts: data?.data.map(
                (post: {
                    id: number
                    attributes: { [key: string]: string | Date }
                }) => parsePost(post)
            ),
            ...data?.meta?.pagination
        }))
        .catch((err) => {
            console.error(err)
            return { posts: [], page: 0, pageSize: 0, pageCount: 0, total: 0 }
        })
}

export async function getSinglePost(
    postSlug: string,
    slug = "inspirations"
): Promise<post | null> {
    console.error(
        `${contentURL}api/${slug}?filters[slug][$eq]=${postSlug}&${populateString}`
    )
    return fetch(
        `${contentURL}api/${slug}?filters[slug][$eq]=${postSlug}&${populateString}`
    )
        .then((res) => res.json())
        .then((data) => parsePost(data?.data[0]))
        .catch((err) => {
            console.log(err)
            return null
        })
}

export async function getWebsiteItems(): Promise<website | null> {
    return fetch(
        `${contentURL}api/website?populate[0]=items&populate[1]=desktopitems`
    )
        .then((res) => res.json())
        .then((data) => data?.data?.attributes)
        .catch((err) => {
            console.log(err)
            return null
        })
}

export async function getSingleProductData(
    handle: string
): Promise<post | null> {
    console.error(
        `${contentURL}api/products?filters[handle][$eq]=${handle}&${populateString}`
    )
    return fetch(
        `${contentURL}api/products?filters[handle][$eq]=${handle}&${populateString}`
    )
        .then((res) => res.json())
        .then((data) => parsePost(data?.data[0]))
        .catch((err) => {
            console.log(err)
            return null
        })
}
