import useToggleState from "@lib/hooks/use-toggle-state"
import { searchClient, SEARCH_INDEX_NAME } from "@lib/search-client"
import Modal from "@modules/common/components/modal"
import Search from "@modules/common/icons/search"
import DesktopHit from "@modules/search/components/desktop-hit"
import DesktopHits from "@modules/search/components/desktop-hits"
import SearchBox from "@modules/search/components/search-box"
import { InstantSearch } from "react-instantsearch-hooks-web"
import { FormattedMessage, useIntl } from "react-intl"
import { OrganismMagnifyingGlass } from "@vectopus/atlas-icons-react"
import dynamic from "next/dynamic"

const DynamicInstantSearch = dynamic(
    () => import("@modules/search/components/instant-search-wrapper"),
    { loading: () => <div>Loading...</div> }
)

const DesktopSearchModal = () => {
    const { state, close, open } = useToggleState()

    return (
        <>
            <button
                aria-label="Zoeken"
                onClick={open}
                className="flex items-center gap-x-3 h-full hover:text-brand"
            >
                <OrganismMagnifyingGlass size={25} weight="thin" />
                <span className={"hidden small:inline-block"}>
                    <FormattedMessage defaultMessage={"Zoeken"} id={"Search"} />
                </span>
            </button>

            <Modal isOpen={state} close={close} size="large">
                <div className={"flex flex-1 h-full"}>
                    <Modal.Body>
                        <DynamicInstantSearch />
                    </Modal.Body>
                </div>
            </Modal>
        </>
    )
}

export default DesktopSearchModal
