import { useMobileMenu } from "@lib/context/mobile-menu-context"
import clsx from "clsx"
import Link from "next/link"
import DesktopSearchModal from "@modules/search/templates/desktop-search-modal"
import CartDropdown from "@modules/layout/components/cart-dropdown"
import HeaderMenu from "@modules/layout/components/header-menu"
import { Account } from "@vectopus/atlas-icons-react"
import { Tooltip } from "react-tooltip"
import Hamburger from "@modules/common/components/hamburger"
import MobileMenu from "@modules/mobile-menu/templates"
import Logo from "@modules/layout/templates/header2/logo"
import { useEffect, useMemo, useState } from "react"
import { useCartDropdown } from "@lib/context/cart-dropdown-context"
import Modal from "@modules/common/components/modal"
import Button from "@modules/common/components/button"
import { useRouter } from "next/router"
import usePreviews from "@lib/hooks/use-previews"
import { StoreGetProductsParams } from "@medusajs/medusa"
import { useInfiniteQuery, useQuery } from "react-query"
import { fetchProductsList } from "@lib/data"
import { useCart } from "medusa-react"
import ProductPreview from "@modules/products/components/product-preview"
import repeat from "@lib/util/repeat"
import SkeletonProductPreview from "@modules/skeletons/components/skeleton-product-preview"
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
import { getWebsiteItems } from "@lib/data/posts"

const CartPopupContent = () => {
    const { cart } = useCart()
    const { productId, closeCartPopup } = useCartDropdown()
    const { push } = useRouter()
    const queryParams: StoreGetProductsParams = useMemo(() => {
        const params: StoreGetProductsParams = {}
        params.collection_id = ["pcol_01GQDXMSSVNZHN0FQ41MC4RCCZ"]
        return params
    }, [cart?.id])
    const {
        data: infiniteData,
        hasNextPage,
        fetchNextPage,
        isLoading: isLoadingProducts,
        isFetchingNextPage
    } = useInfiniteQuery(
        [`accessories`, queryParams, cart?.id],
        ({ pageParam }) => fetchProductsList({ pageParam, queryParams }),
        {
            getNextPageParam: (lastPage) => lastPage.nextPage
        }
    )
    const tagMap: { [key: string]: string } = {
        prod_01GTAQXX384PRXBRZTPRFYQPQH: "ptag_01GTAQK42B0MR0ZCYP1YGWX63Z",
        prod_01GTAQS350TNEK0WQBQXMDJDMD: "ptag_01GTAQKNE9PB4R9A9ZKS9WQT5G",
        prod_01GTAJG4SFKTM1K5RF4A1D6HNB: "ptag_01GTAQK42B0MR0ZCYP1YGWX63Z",
        prod_01GR59Q17DM303PEQTF83MZ53R: "ptag_01GTAQKXCA7SRREPA7DNAXJPMX",
        prod_01GQDNN6S7Z9NRC85VBD82HHEX: "ptag_01GTAQK42B0MR0ZCYP1YGWX63Z",
        prod_01GPYSYFNKYG4WA59S8H3RV3KG: "ptag_01GTAQNZPEVK8EHR93E2D6R02P"
    }
    const previews = usePreviews({
        pages: infiniteData?.pages,
        region: cart?.region
    }).filter(
        (p) =>
            p.title.toLowerCase().includes("high tack") ||
            p.title.toLowerCase().includes("montage schroeven") ||
            p.tags?.some((t) => [tagMap[productId || ""]].includes(t))
    )
    return (
        <>
            <div className="text-3xl font-semibold flex items-center gap-x-4 pr-8 pb-6 pt-8">
                <h2>Vaak samen gekocht</h2>
            </div>
            <div
                className={
                    "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-6 mb-4"
                }
            >
                {previews
                    .sort((a, b) => {
                        if (
                            a.title.toLowerCase().includes("high tack") ||
                            a.title.toLowerCase().includes("montage schroeven")
                        )
                            return 1
                        else if (
                            b.title.toLowerCase().includes("high tack") ||
                            b.title.toLowerCase().includes("montage schroeven")
                        )
                            return -1
                        else return 0
                    })
                    .map((p, i) => (
                        <div
                            key={p.id}
                            className={clsx(
                                "bg-white p-2 md:p-6 gap-y-2 md:gap-y-6 border border-neutral-200",
                                { "hidden md:flex": i > 1 }
                            )}
                        >
                            <ProductPreview
                                {...p}
                                doubleColl={false}
                                newStyle={true}
                            />
                        </div>
                    ))}
                {isLoadingProducts &&
                    !previews.length &&
                    repeat(8).map((index) => (
                        <li key={index}>
                            <SkeletonProductPreview />
                        </li>
                    ))}
            </div>
            <div
                className={
                    "border-t border-t-gray-600 mt-8 pt-4 gap-x-1 md:gap-x-8 flex flex-row"
                }
            >
                <Button
                    onClick={(e) => {
                        closeCartPopup()
                    }}
                    className={
                        "w-auto !text-white !text-xs  md:!text-base !min-h-[20px] !px-3 !py-2 md:!min-h-[50px] md:!px-5 md:!py-[10px]"
                    }
                >
                    <AiOutlineLeft size={28} className={"mr-3"} /> Verder
                    Winkelen
                </Button>
                <Button
                    onClick={(e) => {
                        closeCartPopup()
                        push("/winkelwagen")
                    }}
                    className={
                        "!bg-blue-700 !border-blue-700 hover:!bg-blue-800 !text-white w-auto mr-auto  !text-xs md:!text-base !min-h-[20px] !px-3 !py-2 md:!min-h-[50px] md:!px-5 md:!py-[10px]"
                    }
                >
                    <AiOutlineRight size={28} className={"mr-3"} /> Verder naar
                    bestellen
                </Button>
            </div>
        </>
    )
}

const Header = () => {
    const [mounted, setMounted] = useState(false)
    const { cartPopupState, closeCartPopup, openCartPopup, product } =
        useCartDropdown()
    const { data, isError, isSuccess, isLoading } = useQuery([`get_usps`], () =>
        getWebsiteItems()
    )

    useEffect(() => {
        setMounted(true)
    }, [])
    const { toggle } = useMobileMenu()
    const { push } = useRouter()
    return (
        <>
            <div
                className={clsx(
                    "special-usp-bar md:bg-darkTone text-white flex flex-row flex-wrap md:flex-nowrap justify-center lg:justify-end",
                    "items-right w-full px-0 pt-1 md:pt-2 md:pb-1 text-xs md:text-sm",
                    { "bg-[#e6258b]": !isSuccess }
                )}
            >
                <div
                    className={
                        "mr-0 md:mr-auto flex flex-col md:flex-row w-full md:w-auto md:h-auto text-lg leading-6 mb-1 md:mb-0 gap-x-2 md:gap-x-4 items-center px-0 md:px-8"
                    }
                >
                    <ol
                        className={
                            "w-full text-center flex align-items flex-wrap justify-evenly md:hidden text-xs gap-x-1"
                        }
                    >
                        {isSuccess ? (
                            data?.items?.map((usp) => <li>✓ {usp?.content}</li>)
                        ) : (
                            <>
                                <li>✓ Laagste prijsgarantie</li>
                                <li>✓ Laagste verzendkosten</li>
                                <li>✓ Gratis verzending van akupanelen</li>
                            </>
                        )}
                    </ol>
                    {isSuccess ? (
                        data?.desktopitems
                            ?.sort((a, b) => a.Order - b.Order)
                            ?.map((usp) => (
                                <p
                                    className={
                                        "w-full md:w-auto text-center md:text-left hidden md:inline"
                                    }
                                >
                                    {usp?.content}
                                </p>
                            ))
                    ) : (
                        <>
                            <p
                                className={
                                    "w-full md:w-auto text-center md:text-left hidden md:inline"
                                }
                            >
                                Laagste prijsgarantie
                            </p>
                            <p
                                className={
                                    "w-full md:w-auto text-center md:text-left hidden md:inline"
                                }
                            >
                                Laagste verzendkosten
                            </p>
                            <p
                                className={
                                    "w-full md:w-auto text-center md:text-left hidden md:inline"
                                }
                            >
                                Beste kwaliteit
                            </p>
                            <p
                                className={
                                    "w-full md:w-auto text-center md:text-left hidden md:inline"
                                }
                            >
                                Snelle levering
                            </p>
                        </>
                    )}
                </div>
                <div
                    className={
                        "bg-darkTone md:bg-[transparent] px-2 py-1 md:pt-2 md:pb-1 md:px-8 flex justify-center w-full md:w-auto gap-x-2 md:gap-x-4"
                    }
                >
                    <Link href="/over-ons" prefetch={false}>
                        <a>Over Akupanel Store</a>
                    </Link>
                    <Link href="/reviews" prefetch={false}>
                        <a>Reviews</a>
                    </Link>
                    <Link href="/montage" prefetch={false}>
                        <a>Montage</a>
                    </Link>
                    <Link href="/verzend-retourbeleid" prefetch={false}>
                        <a>Verzending</a>
                    </Link>
                    <Link href="/inspiratie" prefetch={false}>
                        <a>Inspiratie</a>
                    </Link>
                    <Link href="/contact" prefetch={false}>
                        <a>Contact</a>
                    </Link>
                </div>
            </div>
            <div className={clsx("top-0 inset-x-0 z-50 group sticky")}>
                <header
                    className={clsx(
                        "flex flex-row justify-center content-center relative px-4 md:px-8 mx-auto transition border-b duration-500 border-transparent",
                        "bg-white border-gray-200 w-full py-2 md:py-0"
                    )}
                >
                    <div
                        className={
                            "order-1 flex flex-col justify-center items-center text-base font-normal tracking-wide"
                        }
                    >
                        <Link href="/">
                            <a aria-label="Akupanel Store Logo">
                                <Logo />
                            </a>
                        </Link>
                    </div>
                    <div
                        className={clsx(
                            "order-2 flex flex-row small:pt-3 gap-x-3 align-bottom transition duration-500 mx-auto"
                        )}
                    >
                        <div className="block small:hidden">
                            <Hamburger setOpen={toggle} />
                        </div>

                        <div className="hidden small:flex h-full">
                            <HeaderMenu />
                        </div>
                    </div>
                    <div
                        className={clsx(
                            "order-3 small:pt-3 flex flex-row content-center items-center gap-x-3 small:gap-x-6 transition duration-500"
                        )}
                    >
                        <DesktopSearchModal />
                        <Link href="/account" passHref prefetch={false}>
                            <a id={"account-icon"} aria-label="Mijn Account">
                                <>
                                    <Account size={25} weight="thin" />
                                </>
                            </a>
                        </Link>
                        <CartDropdown />
                    </div>
                    <MobileMenu />
                    {mounted && (
                        <Tooltip
                            anchorId="account-icon"
                            content="Mijn account"
                            place="bottom"
                        />
                    )}
                </header>
                <nav className="flex small:hidden flex-row justify-center content-center relative px-4 md:px-8 mx-auto transition bg-white border-gray-200 w-full">
                    <HeaderMenu />
                </nav>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: isSuccess
                        ? `.special-usp-bar { background-color: ${
                              data?.uspbannerdesktop ?? "#262221"
                          } !important;} @media (max-width:768px){ .special-usp-bar { background-color: ${
                              data?.uspbanner ?? "#E6258B"
                          } !important;} }`
                        : ""
                }}
            />
            <Modal
                className={"!max-w-[100%] md:!max-w-[80%] !p-4 md:!p-10"}
                size={"large"}
                isOpen={cartPopupState}
                close={closeCartPopup}
            >
                <Modal.Body>
                    <div
                        className={
                            "flex flex-col md:flex-row gap-x-4 bg-green-400 diagonal-background2 p-2 md:p-4 items-center"
                        }
                    >
                        <div className={"flex flex-col gap-y-1"}>
                            <h2
                                className={
                                    "text-center md:text-left text-lg md:text-2xl font-bold text-white text-shadow"
                                }
                            >
                                Het artikel is toegevoegd aan je winkelwagen
                            </h2>
                            <p
                                className={
                                    "text-center md:text-left font-semibold text-white text-shadow"
                                }
                            >
                                {product}
                            </p>
                        </div>
                        <div
                            className={
                                "flex flex-col gap-y-1 ml-0 md:ml-auto mt-3 md:mt-0"
                            }
                        >
                            <Button
                                onClick={(e) => {
                                    closeCartPopup()
                                    push("/winkelwagen")
                                }}
                                className={
                                    "ml-0 md:ml-auto !bg-blue-700 !border-blue-700 hover:!bg-blue-800 !text-white text-base"
                                }
                            >
                                Verder naar bestellen
                            </Button>
                            <span
                                className={
                                    "text-center md:text-left font-semibold text-base !text-white text-shadow"
                                }
                            >
                                <span
                                    className={"cursor-pointer"}
                                    onClick={() => {
                                        closeCartPopup()
                                    }}
                                >
                                    Of verder winkelen
                                </span>
                            </span>
                        </div>
                    </div>
                    <CartPopupContent />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Header
