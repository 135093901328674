import { Listbox, Transition } from "@headlessui/react"
import { useStore } from "@lib/context/store-context"
import useToggleState from "@lib/hooks/use-toggle-state"
import { useRegions } from "medusa-react"
import { Fragment, useEffect, useMemo, useState } from "react"
import ReactCountryFlag from "react-country-flag"
import { getLocale } from "@lib/context/intl-context"
import { FormattedMessage } from "react-intl"

type CountryOption = {
    country: string
    region: string
    label: string
}

const CountrySelect = () => {
    const { countryCode, setRegion } = useStore()
    const { regions } = useRegions()
    const [current, setCurrent] = useState<CountryOption | undefined>(undefined)
    const { state, open, close } = useToggleState()

    const options: CountryOption[] | undefined = useMemo(() => {
        return regions
            ?.map((r) => {
                return r.countries.map((c) => ({
                    country: c.iso_2,
                    region: r.id,
                    label: c.display_name,
                }))
            })
            .flat()
    }, [regions])

    useEffect(() => {
        if (countryCode) {
            const option = options?.find((o) => o.country === countryCode)
            setCurrent(option)
        }
    }, [countryCode, options])

    const handleChange = (option: CountryOption) => {
        setRegion(option.region, option.country)
        const currentCountry = options?.find(
            (o) => o.country === option.country
        )
        setCurrent(currentCountry)
        close()
    }

    return (
        <div onMouseEnter={open} onMouseLeave={close}>
            <Listbox onChange={handleChange} defaultValue={current}>
                <Listbox.Button className="py-1 w-full">
                    <div className="text-base-regular flex items-center gap-x-2 xsmall:justify-start">
                        <span>
                            <FormattedMessage
                                defaultMessage="Verzenden naar:"
                                id="Ship to text"
                            />
                        </span>
                        {current && (
                            <span className="text-small-semi flex items-center gap-x-2">
                                <ReactCountryFlag
                                    svg
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                    }}
                                    alt={current.label}
                                    countryCode={current.country}
                                />
                                {current.label}
                            </span>
                        )}
                    </div>
                </Listbox.Button>
                <div className="relative w-full min-w-[316px]">
                    <Transition
                        show={state}
                        as={Fragment}
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute -bottom-[calc(100%-36px)] left-0 max-h-[442px] overflow-y-scroll z-[900] bg-white drop-shadow-md text-small-regular uppercase text-black no-scrollbar min-w-[150px]"
                            static
                        >
                            {options?.map((o) => {
                                return (
                                    <Listbox.Option
                                        key={o.country}
                                        value={o}
                                        className="py-2 hover:bg-gray-200 px-3 cursor-pointer flex items-center gap-x-2"
                                    >
                                        <ReactCountryFlag
                                            svg
                                            style={{
                                                width: "16px",
                                                height: "16px",
                                            }}
                                            alt={o.label}
                                            countryCode={o.country}
                                        />{" "}
                                        {o.label}
                                    </Listbox.Option>
                                )
                            })}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default CountrySelect
