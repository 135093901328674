import { getPercentageDiff } from "@lib/util/get-precentage-diff"
import { Product, Region } from "@medusajs/medusa"
import { formatAmount } from "medusa-react"
import { ExtendedProduct, ProductPreviewType } from "types/global"
import { CalculatedVariant } from "types/medusa"
import { useQuery } from "react-query"
import { ReviewResponse } from "@lib/data/_types"
import { MEDUSA_BACKEND_URL } from "@lib/config"

const transformProductPreview = (
    product: ExtendedProduct,
    region: Region
): ProductPreviewType => {
    const variants = product.variants as CalculatedVariant[]
    let cheapestVariant = undefined

    if (variants?.length > 0) {
        cheapestVariant = variants.reduce((acc, curr) => {
            if (curr.prices.length > 0) {
                curr.calculated_price =
                    curr.prices.find((price) => price.price_list !== null)
                        ?.amount ??
                    curr.prices.reduce((pacc, pcurr) =>
                        pacc.amount < pcurr.amount ? pacc : pcurr
                    ).amount ??
                    curr.calculated_price
            }
            if (acc.prices.length > 0) {
                acc.calculated_price =
                    acc.prices.find((price) => price.price_list !== null)
                        ?.amount ??
                    acc.prices.reduce((pacc, pcurr) =>
                        pacc.amount < pcurr.amount ? pacc : pcurr
                    ).amount ??
                    acc.calculated_price
            }
            return acc.calculated_price > curr.calculated_price ? curr : acc
        }, variants[0])
    }
    const calculatedPrice = formatAmount({
        amount: cheapestVariant?.calculated_price ?? 0,
        region: region,
        includeTaxes: false,
        locale: "nl-NL"
    })
    const originalPrice = formatAmount({
        amount: cheapestVariant?.original_price ?? 0,
        region: region,
        includeTaxes: false,
        locale: "nl-NL"
    })
    const response: ProductPreviewType = {
        id: product.id,
        title: product.title,
        handle: product.handle,
        thumbnail: product.thumbnail,
        secondThumbnail: product.images[1]?.url,
        presale: !!product?.tags?.find((tag) => tag?.value === "presale")?.id,
        tags: product.tags.map((tag) => tag.id),
        deliveryDate: product.deliveryDate,
        inStock: ((product?.variants.length > 1 &&
            product?.variants.reduce(
                (acc, cur) =>
                    cur?.allow_backorder
                        ? 1
                        : Math.max(0, cur?.inventory_quantity),
                0
            ) > 0) ||
            product?.variants[0].inventory_quantity > 0) as boolean,
        variant: variants[0]?.id,
        price: cheapestVariant
            ? {
                  calculated_price:
                      calculatedPrice.substring(calculatedPrice.length - 2) ===
                      "00"
                          ? `${calculatedPrice.substring(
                                1,
                                calculatedPrice.length - 2
                            )}-`
                          : calculatedPrice.substring(
                                1,
                                calculatedPrice.length
                            ),
                  original_price:
                      originalPrice.substring(originalPrice.length - 2) === "00"
                          ? `${originalPrice.substring(
                                1,
                                originalPrice.length - 2
                            )}-`
                          : originalPrice.substring(1, originalPrice.length),
                  difference: getPercentageDiff(
                      cheapestVariant.original_price,
                      cheapestVariant.calculated_price
                  ),
                  price_type: cheapestVariant.calculated_price_type
              }
            : undefined
    }
    if (typeof product?.reviews !== "undefined")
        response.reviews = product.reviews
    return response
}

export default transformProductPreview
