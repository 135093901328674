const SkeletonProductPreview = () => {
  return (
    <div className="animate-pulse">
      <div className="aspect-[29/34] w-full bg-gray-100"></div>
      <div className="text-base-regular mt-2">
        <div className="w-3/5 h-6 bg-gray-100"></div>

        <div className="w-2/5 h-6 bg-gray-100 mt-2"></div>
      </div>
    </div>
  )
}

export default SkeletonProductPreview
