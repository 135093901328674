import { useMobileMenu } from "@lib/context/mobile-menu-context"
import Container from "@modules/mobile-menu/components/container"
import dynamic from "next/dynamic"

const DynamicCountryMenu = dynamic(
    () => import("@modules/mobile-menu/components/country-menu"),
    { loading: () => <div>Loading...</div> }
)
const DynamicSearchMenu = dynamic(
    () => import("@modules/mobile-menu/components/search-menu"),
    { loading: () => <div>Loading...</div> }
)
const DynamicMainMenu = dynamic(
    () => import("@modules/mobile-menu/components/main-menu"),
    { loading: () => <div>Loading...</div> }
)

const MobileMenu = () => {
    const {
        screen: [currentScreen],
    } = useMobileMenu()

    return (
        <Container>
            <div className="flex flex-col flex-1">
                {(() => {
                    switch (currentScreen) {
                        case "country":
                            return <DynamicCountryMenu />
                        case "search":
                            return <DynamicSearchMenu />
                        default:
                            return <DynamicMainMenu />
                    }
                })()}
            </div>
        </Container>
    )
}

export default MobileMenu
