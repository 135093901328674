import Image from "next/image"
import { useForm } from "react-hook-form"
import { useState } from "react"
import Spinner from "@modules/common/icons/spinner"
import Button from "@modules/common/components/button"
import Input from "@modules/common/components/input"
import { MEDUSA_BACKEND_URL } from "@lib/config"
import { FormattedMessage, useIntl } from "react-intl"
import panels from "../../../../../public/images/panels.jpg"

type FormValues = {
    email: string
}

const isValidEmail = (email: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const FooterCTA = () => {
    const intl = useIntl()
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [success, setSuccess] = useState<string | undefined>(undefined)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<FormValues>()

    const submit = handleSubmit(async (data: FormValues) => {
        setSubmitting(true)
        setError(undefined)

        const payload = {
            email_address: data.email,
            status: "subscribed"
        }
        const url = `${MEDUSA_BACKEND_URL}store/newsletter`

        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log(data)
                if (data.error === null || data.error.length < 1) {
                    reset({
                        email: ""
                    })
                    setSuccess(
                        intl.formatMessage({
                            id: "newsletter-success",
                            defaultMessage:
                                "Je bent ingeschreven voor onze nieuwsbrief! Controleer uw e-mail voor een bevestiging."
                        })
                    )
                    setSubmitting(false)
                } else setError(data.error)
                setSubmitting(false)
            })
            .catch((error) => {
                console.error(error)
                setError(
                    intl.formatMessage({
                        id: "newsletter-error",
                        defaultMessage:
                            "Er is iets misgegaan. Probeer het later opnieuw."
                    })
                )
                setSubmitting(false)
            })
    })
    return (
        <>
            <h3 className={"text-lg lg:text-2xl font-sans font-semibold"}>
                Blijf op de hoogte
            </h3>
            <form onSubmit={submit} className="mt-6 max-w-[500px]">
                <p className={"text-md"}>
                    Schrijf je in voor onze nieuwsbrief en blijf up-to-date met
                    de laatste akupanelen trends.
                </p>
                <div className={"w-full flex flex-row mt-5"}>
                    <Input
                        label="Email"
                        {...register("email", {
                            required: intl.formatMessage({
                                id: "email-required",
                                defaultMessage: "Email is verplicht"
                            }),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: intl.formatMessage({
                                    id: "email-invalid",
                                    defaultMessage: "Ongeldig e-mailadres"
                                })
                            }
                        })}
                        required
                        errors={errors}
                        autoComplete="email-address"
                        mainClassName={"w-full bg-white text-darkTone"}
                        aria-label={"Email address"}
                    />
                    <Button
                        className="min-h-0 w-[100px] min-h-[46px] bg-accent border-accent"
                        type={"submit"}
                        aria-label={"Aanmelden"}
                        disabled={submitting}
                    >
                        <FormattedMessage
                            id={"nieuwsbrief-button"}
                            defaultMessage="Aanmelden"
                        />
                        {submitting && <Spinner />}
                    </Button>
                </div>
                {error && (
                    <div className="text-rose-500 text-small-regular py-2 font-mono">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="text-green-500 text-base-regular py-2 font-mono">
                        {success}
                    </div>
                )}
            </form>
        </>
    )
}

export default FooterCTA
