import { Image as MedusaImage } from "@medusajs/medusa"
import PlaceholderImage from "@modules/common/icons/placeholder-image"
import clsx from "clsx"
import Image from "next/image"
import React from "react"
import { ImageLoader } from "@lib/util/loader"

type ThumbnailProps = {
    thumbnail?: string | null
    images?: MedusaImage[] | null
    size?: "small" | "medium" | "large" | "full" | number
    containerClassName?: string | null
    objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down"
    sale?: boolean
}

const Thumbnail: React.FC<ThumbnailProps> = ({
    thumbnail,
    images,
    size = "small",
    containerClassName = null,
    objectFit = "cover",
    sale = false
}) => {
    const initialImage = thumbnail || images?.[0]?.url

    return (
        <div
            className={clsx(containerClassName ?? "relative aspect-[29/34]", {
                "w-[180px]": size === "small",
                "w-[290px]": size === "medium",
                "w-[440px]": size === "large",
                "w-full": size === "full"
            })}
        >
            {sale && (
                <div className="absolute top-2 left-2 bg-gray-300 text-red-600 z-20 px-2 py-0.5 text-xs pointer-events-none antialiased tracking-[.22em]">
                    SALE
                </div>
            )}
            <ImageOrPlaceholder
                image={initialImage}
                size={size}
                objectFit={objectFit}
            />
        </div>
    )
}

const ImageOrPlaceholder = ({
    image,
    size,
    objectFit
}: Pick<ThumbnailProps, "size"> &
    Pick<ThumbnailProps, "objectFit"> & { image?: string }) => {
    return image ? (
        typeof size === "number" ? (
            <Image
                loader={ImageLoader}
                src={image}
                sizes={`(max-width: ${size}px) 100vw, ${size}px`}
                alt="Thumbnail"
                layout="fill"
                objectFit={objectFit ?? "cover"}
                objectPosition="center"
                className="absolute inset-0"
                draggable={false}
                loading={"lazy"}
            />
        ) : (
            <Image
                loader={ImageLoader}
                src={image}
                alt="Thumbnail"
                layout="fill"
                objectFit={objectFit ?? "cover"}
                objectPosition="center"
                className="absolute inset-0"
                draggable={false}
                loading={"lazy"}
            />
        )
    ) : (
        <div className="w-full h-full absolute inset-0 bg-gray-100 flex items-center justify-center">
            <PlaceholderImage size={size === "small" ? 16 : 24} />
        </div>
    )
}

export default Thumbnail
