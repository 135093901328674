import { medusaClient } from "@lib/config"
import { getPercentageDiff } from "@lib/util/get-precentage-diff"
import { Product, ProductCollection, Region } from "@medusajs/medusa"
import { formatAmount, useCart } from "medusa-react"
import { useQuery } from "react-query"
import { ProductPreviewType } from "types/global"
import { CalculatedVariant } from "types/medusa"

type LayoutCollection = {
    id: string
    title: string
    handle: string
}

const fetchCollectionData = async (): Promise<LayoutCollection[]> => {
    let collections: ProductCollection[] = []
    let offset = 0
    let count = 1

    do {
        await medusaClient.collections
            .list({ offset })
            .then(({ collections: newCollections, count: newCount }) => {
                collections = [...collections, ...newCollections]
                count = newCount
                offset = collections.length
            })
            .catch((_) => {
                count = 0
            })
    } while (collections.length < count)

    return collections.map((c) => ({
        id: c.id,
        title: c.title,
        handle: c.handle
    }))
}

export const useNavigationCollections = () => {
    const queryResults = useQuery(
        "navigation_collections",
        fetchCollectionData,
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    )

    return queryResults
}

const fetchFeaturedProducts = async (
    cartId: string,
    region: Region
): Promise<ProductPreviewType[]> => {
    const products = await medusaClient.products
        .list({
            is_giftcard: false,
            limit: 4,
            id: [
                "prod_01GTAQXX384PRXBRZTPRFYQPQH",
                "prod_01GTAQS350TNEK0WQBQXMDJDMD",
                "prod_01GTAJG4SFKTM1K5RF4A1D6HNB",
                "prod_01GR59Q17DM303PEQTF83MZ53R",
                "prod_01GQDNN6S7Z9NRC85VBD82HHEX",
                "prod_01GPYSYFNKYG4WA59S8H3RV3KG"
            ],
            cart_id: cartId
        })
        .then(({ products }) => products)
        .catch((_) => [] as Product[])

    return products
        .filter((p) => !!p.variants)
        .map((p) => {
            const variants = p.variants as CalculatedVariant[]

            const cheapestVariant = variants.reduce((acc, curr) => {
                if (acc.calculated_price > curr.calculated_price) {
                    return curr
                }
                return acc
            }, variants[0])
            const calculatedPrice = formatAmount({
                amount: cheapestVariant.calculated_price,
                region: region,
                includeTaxes: false,
                locale: "nl-NL"
            })
            const originalPrice = formatAmount({
                amount: cheapestVariant.original_price,
                region: region,
                includeTaxes: false,
                locale: "nl-NL"
            })

            return {
                id: p.id,
                title: p.title,
                handle: p.handle,
                thumbnail: p.thumbnail,
                secondThumbnail: p.images[1]?.url ?? null,
                presale: !!p?.tags?.find((tag) => tag?.value === "presale")?.id,
                inStock: ((p?.variants.length > 1 &&
                    p?.variants.reduce(
                        (acc, cur) =>
                            cur?.allow_backorder
                                ? 1
                                : Math.max(0, cur?.inventory_quantity),
                        0
                    ) > 0) ||
                    p?.variants[0].inventory_quantity > 0) as boolean,
                price: cheapestVariant
                    ? {
                          calculated_price:
                              calculatedPrice.substring(
                                  calculatedPrice.length - 2
                              ) === "00"
                                  ? `${calculatedPrice.substring(
                                        1,
                                        calculatedPrice.length - 2
                                    )}-`
                                  : calculatedPrice,
                          original_price:
                              originalPrice.substring(
                                  originalPrice.length - 2
                              ) === "00"
                                  ? `${originalPrice.substring(
                                        1,
                                        originalPrice.length - 2
                                    )}-`
                                  : originalPrice,
                          difference: getPercentageDiff(
                              cheapestVariant.original_price,
                              cheapestVariant.calculated_price
                          ),
                          price_type: cheapestVariant.calculated_price_type
                      }
                    : {
                          calculated_price: "N/A",
                          original_price: "N/A",
                          difference: "N/A",
                          price_type: "default"
                      }
            }
        })
}

export const useFeaturedProductsQuery = () => {
    const { cart } = useCart()

    const queryResults = useQuery(
        ["layout_featured_products", cart?.id, cart?.region],
        () => fetchFeaturedProducts(cart?.id!, cart?.region!),
        {
            enabled: !!cart?.id && !!cart?.region,
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    )

    return queryResults
}
