import clsx from "clsx"

const Logo = () => (
    <svg
        version="1.1"
        id="akupanel_logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 492.31 65.79"
        className={clsx(
            "transition-all w-200 md:w-330 h-[24px] md:h-[46px] for-white-bg"
        )}
    >
        <g>
            <path
                className="st0"
                d="M29.5,57.8c-13,0-23.8-10-25-22.7h21.7v14v1.5v3c1.1,0.2,2.1,0.3,3.2,0.3c0.5,0,1,0,1.5-0.1v-3.2v-1.3V7.7V6.3
		V3.3c-0.5,0-1,0-1.5,0c-13.8,0-25.4,9.5-28.6,22.4h4.6C8.3,16.2,16.4,9.2,26.3,7.9v22.4H4.6H0.8H0.1C0,31.1,0,31.9,0,32.7
		C0,49,13.2,62.2,29.5,62.2c13.9,0,25.5-9.6,28.6-22.4h-4.6C50.5,50.2,40.9,57.8,29.5,57.8z"
            />
            <path
                className="st1"
                d="M35.8,3.9v2.4v2.1v39.7v2.2v2.6c1.7-0.5,3.3-1.3,4.7-2.2v-0.4v-5.1V10.2c7.6,3.7,13.1,11.3,13.9,20.1h-8.5
		c0.1,0.8,0.2,1.6,0.2,2.4c0,0.8-0.1,1.6-0.2,2.3h8.5h2.1h2.3c0.1-0.8,0.1-1.5,0.1-2.3C59,18.6,49,6.8,35.8,3.9z"
            />
        </g>
        <g>
            <path
                d="M76.3,54.6c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.3-0.9-0.6C74.1,53.6,74,53.3,74,53c0-0.2,0-0.3,0.1-0.4l12.5-40.5
		c0.2-0.5,0.5-0.9,0.9-1.1c0.4-0.2,0.9-0.3,1.4-0.3c0.6,0,1.1,0.1,1.5,0.4c0.5,0.2,0.7,0.6,0.9,1.1l12.4,40.5c0,0.1,0.1,0.2,0.1,0.4
		c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.2-0.7,0.2-1.1,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.2-0.5-0.4-0.6-0.8
		l-2.7-9.2H80.6l-2.7,9.2c-0.1,0.4-0.3,0.6-0.6,0.8C77,54.6,76.7,54.6,76.3,54.6z M81.6,41h14.5l-7.2-24L81.6,41z"
            />
            <path
                d="M111.4,54.6c-0.5,0-1-0.1-1.4-0.4c-0.4-0.3-0.6-0.6-0.6-1.2V12.5c0-0.5,0.2-0.9,0.6-1.1c0.4-0.2,0.9-0.4,1.4-0.4
		c0.5,0,0.9,0.1,1.3,0.4c0.4,0.2,0.6,0.6,0.6,1.1v21.1l17.2-22c0.3-0.4,0.7-0.6,1.2-0.6c0.4,0,0.7,0.1,1,0.3
		c0.3,0.2,0.6,0.5,0.8,0.8c0.2,0.3,0.3,0.6,0.3,1c0,0.1,0,0.3-0.1,0.4c0,0.2-0.1,0.3-0.2,0.4l-12.8,15.9L135.6,52
		c0.2,0.2,0.2,0.5,0.2,0.8c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-0.3,0-0.5-0.1-0.8-0.2
		c-0.3-0.2-0.5-0.4-0.7-0.7l-13.8-20.6l-4.9,6.1v13.7c0,0.5-0.2,0.9-0.6,1.2C112.4,54.5,111.9,54.6,111.4,54.6z"
            />
            <path
                d="M152.9,55c-2.3,0-4.5-0.4-6.3-1.3c-1.9-0.8-3.4-2.2-4.5-4.1c-1.1-1.9-1.7-4.4-1.7-7.5V12.5c0-0.5,0.2-0.9,0.6-1.1
		c0.4-0.2,0.9-0.4,1.4-0.4c0.5,0,1,0.1,1.4,0.4c0.4,0.2,0.6,0.6,0.6,1.1v29.7c0,3.1,0.8,5.4,2.3,6.9c1.5,1.5,3.6,2.2,6.3,2.2
		c2.7,0,4.8-0.7,6.3-2.2c1.5-1.5,2.3-3.8,2.3-6.9V12.5c0-0.5,0.2-0.9,0.6-1.1c0.4-0.2,0.9-0.4,1.4-0.4c0.5,0,1,0.1,1.4,0.4
		c0.4,0.2,0.6,0.6,0.6,1.1v29.7c0,3.1-0.5,5.6-1.6,7.5c-1.1,1.9-2.6,3.2-4.5,4.1C157.4,54.6,155.2,55,152.9,55z"
            />
            <path
                d="M176.2,54.6c-0.5,0-1-0.1-1.4-0.4c-0.4-0.3-0.6-0.6-0.6-1.2V12.5c0-0.4,0.1-0.7,0.4-1s0.7-0.4,1.2-0.5h11
		c2.3,0,4.4,0.4,6.3,1.3c1.9,0.8,3.3,2.2,4.4,4c1.1,1.8,1.6,4.3,1.6,7.4v0.6c0,3.1-0.5,5.6-1.6,7.5c-1.1,1.9-2.6,3.2-4.5,4.1
		c-1.9,0.8-4,1.3-6.3,1.3h-8.6v16.1c0,0.5-0.2,0.9-0.6,1.2C177.2,54.5,176.7,54.6,176.2,54.6z M178.2,33.6h8.6
		c2.7,0,4.7-0.7,6.2-2.2c1.5-1.5,2.2-3.8,2.2-6.9v-0.7c0-3.1-0.7-5.4-2.2-6.9c-1.5-1.5-3.6-2.2-6.2-2.2h-8.6V33.6z"
            />
            <path
                d="M202.2,54.6c-0.3,0-0.7-0.1-1-0.2c-0.4-0.1-0.7-0.3-0.9-0.6c-0.2-0.3-0.4-0.5-0.4-0.9c0-0.2,0-0.3,0.1-0.4l12.5-40.5
		c0.2-0.5,0.5-0.9,0.9-1.1c0.4-0.2,0.9-0.3,1.4-0.3c0.6,0,1.1,0.1,1.5,0.4c0.5,0.2,0.7,0.6,0.9,1.1l12.4,40.5c0,0.1,0.1,0.2,0.1,0.4
		c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.2-0.7,0.2-1.1,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.2-0.5-0.4-0.6-0.8
		l-2.7-9.2h-16.5l-2.7,9.2c-0.1,0.4-0.3,0.6-0.6,0.8C202.9,54.6,202.6,54.6,202.2,54.6z M207.5,41H222l-7.2-24L207.5,41z"
            />
            <path
                d="M237.3,54.6c-0.5,0-1-0.1-1.4-0.4c-0.4-0.3-0.6-0.6-0.6-1.2V12.5c0-0.6,0.2-0.9,0.6-1.2c0.4-0.2,0.9-0.3,1.4-0.3
		c0.5,0,0.9,0.1,1.3,0.4c0.4,0.3,0.7,0.6,1,1c0.3,0.4,0.6,0.9,0.8,1.4l16.1,31.7v-33c0-0.5,0.2-0.9,0.6-1.1c0.4-0.2,0.9-0.4,1.4-0.4
		c0.5,0,0.9,0.1,1.3,0.4c0.4,0.2,0.6,0.6,0.6,1.1v40.6c0,0.5-0.2,0.9-0.6,1.2s-0.9,0.4-1.3,0.4c-0.6,0-1.1-0.2-1.5-0.7
		c-0.5-0.5-0.8-1-1.1-1.6l-16.6-32.3v33c0,0.5-0.2,0.9-0.6,1.2C238.3,54.5,237.8,54.6,237.3,54.6z"
            />
            <path
                d="M271.4,54.6c-0.4,0-0.8-0.1-1.2-0.4c-0.4-0.3-0.6-0.6-0.6-1.2V12.6c0-0.5,0.2-0.9,0.6-1.2c0.4-0.3,0.8-0.4,1.2-0.4h21.2
		c0.5,0,0.9,0.2,1.1,0.6c0.2,0.4,0.4,0.8,0.4,1.2c0,0.5-0.1,0.9-0.4,1.3c-0.3,0.4-0.6,0.6-1.1,0.6h-19.1v16.4h9.4
		c0.5,0,0.8,0.2,1.1,0.5c0.3,0.4,0.4,0.8,0.4,1.3c0,0.4-0.1,0.8-0.4,1.1s-0.6,0.5-1.1,0.5h-9.4V51h19.1c0.5,0,0.8,0.2,1.1,0.5
		c0.3,0.4,0.4,0.8,0.4,1.3c0,0.4-0.1,0.8-0.4,1.2s-0.6,0.6-1.1,0.6H271.4z"
            />
            <path
                d="M301.7,54.6c-0.4,0-0.8-0.1-1.2-0.4c-0.4-0.3-0.6-0.6-0.6-1.2V12.5c0-0.5,0.2-0.9,0.6-1.1c0.4-0.2,0.9-0.4,1.4-0.4
		c0.5,0,0.9,0.1,1.3,0.4c0.4,0.2,0.6,0.6,0.6,1.1V51h17c0.5,0,0.8,0.2,1,0.6c0.2,0.4,0.3,0.8,0.3,1.2c0,0.5-0.1,0.9-0.3,1.3
		s-0.5,0.6-1,0.6H301.7z"
            />
            <path
                d="M349.8,55.1c-2.6,0-4.9-0.4-6.9-1.1c-2-0.8-3.5-1.7-4.7-2.8s-1.7-2.1-1.7-3.1c0-0.5,0.1-1,0.4-1.5c0.3-0.5,0.6-1,1-1.3
		c0.4-0.4,0.9-0.6,1.3-0.6c0.6,0,1.1,0.2,1.6,0.7c0.5,0.5,1.1,1,1.8,1.6c0.7,0.6,1.6,1.2,2.7,1.7c1.1,0.5,2.4,0.7,4.1,0.7
		c1.4,0,2.7-0.2,3.8-0.7c1.1-0.5,2-1.2,2.7-2.1s1-2.1,1-3.6c0-1.5-0.3-2.8-1-3.8c-0.7-1-1.6-1.8-2.8-2.5c-1.2-0.7-2.4-1.3-3.8-1.8
		c-1.4-0.6-2.7-1.2-4.1-1.8s-2.7-1.4-3.8-2.3c-1.2-0.9-2.1-2-2.8-3.4c-0.7-1.4-1-3.1-1-5.2c0-2.2,0.4-4,1.2-5.5
		c0.8-1.5,1.9-2.6,3.3-3.5c1.4-0.9,2.8-1.5,4.5-1.9c1.6-0.4,3.2-0.6,4.8-0.6c1,0,2.1,0.1,3.3,0.2c1.2,0.1,2.4,0.4,3.6,0.7
		c1.2,0.3,2.1,0.8,2.9,1.3c0.8,0.6,1.1,1.2,1.1,2c0,0.4-0.1,0.9-0.3,1.4s-0.5,1-0.9,1.4c-0.4,0.4-0.9,0.6-1.5,0.6
		c-0.5,0-1.1-0.2-1.7-0.6s-1.5-0.8-2.5-1.1c-1-0.4-2.4-0.6-4-0.6c-1.4,0-2.7,0.2-3.8,0.6c-1.1,0.4-2,1-2.7,1.8c-0.6,0.8-1,1.8-1,3.1
		s0.3,2.3,1,3.1c0.7,0.8,1.6,1.5,2.8,2.1c1.2,0.6,2.4,1.1,3.8,1.6c1.4,0.5,2.7,1.1,4.1,1.8c1.4,0.7,2.6,1.5,3.8,2.5
		c1.2,1,2.1,2.3,2.8,3.8c0.7,1.5,1,3.4,1,5.7c0,2.9-0.6,5.2-1.8,7.1s-2.8,3.3-4.8,4.2C354.7,54.7,352.4,55.1,349.8,55.1z"
            />
            <path
                d="M380.3,54.6c-0.8,0-1.5-0.2-2.2-0.5s-1-0.9-1-1.6V16.8h-9.2c-0.6,0-1.1-0.3-1.5-0.9c-0.4-0.6-0.5-1.3-0.5-2
		c0-0.7,0.2-1.4,0.5-2c0.3-0.6,0.8-0.9,1.6-0.9h24.8c0.8,0,1.3,0.3,1.6,0.9s0.5,1.2,0.5,1.9c0,0.8-0.2,1.5-0.5,2.1
		c-0.3,0.6-0.9,0.9-1.6,0.9h-9.2v35.7c0,0.7-0.3,1.2-1,1.6S381,54.6,380.3,54.6z"
            />
            <path
                d="M411.2,55c-2.5,0-4.8-0.4-6.8-1.3c-2-0.9-3.6-2.3-4.8-4.2c-1.2-1.9-1.8-4.5-1.8-7.8V24.3c0-3.2,0.6-5.8,1.8-7.8
		c1.2-1.9,2.8-3.4,4.8-4.2c2-0.9,4.3-1.3,6.8-1.3c2.5,0,4.8,0.4,6.8,1.3c2,0.9,3.6,2.3,4.8,4.2s1.8,4.5,1.8,7.8v17.4
		c0,3.2-0.6,5.8-1.8,7.8c-1.2,1.9-2.8,3.4-4.8,4.2C416.1,54.6,413.8,55,411.2,55z M411.2,49.4c2.2,0,4-0.6,5.3-1.9s1.9-3.2,1.9-5.8
		V24.3c0-2.6-0.6-4.6-1.9-5.8c-1.3-1.3-3-1.9-5.2-1.9c-2.2,0-4,0.6-5.2,1.9c-1.3,1.3-1.9,3.2-1.9,5.8v17.4c0,2.6,0.6,4.6,1.8,5.8
		S409,49.4,411.2,49.4z"
            />
            <path
                d="M455.6,54.8c-0.5,0-1-0.2-1.4-0.5c-0.4-0.4-0.8-0.8-1-1.3l-8.8-17.1h-6v16.6c0,0.7-0.3,1.2-1,1.6s-1.4,0.5-2.2,0.5
		c-0.8,0-1.5-0.2-2.2-0.5s-1-0.9-1-1.6V13.1c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.6-0.6h11.3c2.5,0,4.8,0.4,6.9,1.1
		c2.1,0.7,3.7,2,5,3.8c1.2,1.8,1.8,4.2,1.8,7.3c0,2.4-0.4,4.4-1.1,6c-0.7,1.6-1.7,2.9-3,3.8s-2.7,1.6-4.3,2.1l8.4,15.7
		c0.1,0.1,0.1,0.3,0.2,0.4c0,0.2,0.1,0.3,0.1,0.4c0,0.5-0.2,1-0.6,1.5c-0.4,0.5-0.9,0.9-1.5,1.2C456.9,54.7,456.3,54.8,455.6,54.8z
		 M438.4,30.8h7.2c2.2,0,4-0.5,5.3-1.6c1.3-1.1,2-2.9,2-5.5c0-2.6-0.7-4.4-2-5.5c-1.3-1.1-3.1-1.6-5.3-1.6h-7.2V30.8z"
            />
            <path
                d="M467.9,54.6c-0.7,0-1.3-0.2-1.9-0.5c-0.6-0.4-0.9-0.9-0.9-1.6V13.1c0-0.7,0.3-1.2,0.9-1.6c0.6-0.4,1.2-0.5,1.9-0.5h21.2
		c0.8,0,1.3,0.3,1.6,0.9s0.5,1.2,0.5,1.8c0,0.8-0.2,1.4-0.5,2c-0.4,0.6-0.9,0.9-1.6,0.9h-17.7v13.7h9.2c0.7,0,1.2,0.3,1.6,0.8
		c0.4,0.5,0.5,1.1,0.5,1.8c0,0.6-0.2,1.1-0.5,1.6s-0.9,0.8-1.6,0.8h-9.2V49h17.7c0.7,0,1.2,0.3,1.6,0.8c0.4,0.6,0.5,1.2,0.5,2
		c0,0.6-0.2,1.3-0.5,1.8s-0.9,0.9-1.6,0.9H467.9z"
            />
        </g>
    </svg>
)

export default Logo
