import Footer from "@modules/layout/templates/footer"
import Nav from "@modules/layout/templates/nav"
import React, { useEffect, useState } from "react"
import ArrowRight from "@modules/common/icons/arrow-right"
import Link from "next/link"
import Header from "@modules/layout/templates/header2"
import { useRouter } from "next/router"

const Layout: React.FC = ({ children }) => {
    const { pathname, asPath } = useRouter()
    const [showSampleAanvraag, setShowSampleAanvraag] = useState(true)
    useEffect(() => {
        if (
            pathname.includes("producten/[handle]") ||
            pathname.includes("akoestische-panelen") ||
            asPath.includes("producten/[handle]") ||
            asPath.includes("akoestische-panelen")
        )
            setShowSampleAanvraag(false)
        return () => setShowSampleAanvraag(true)
    }, [pathname])

    return (
        <div>
            <Header />
            <main className="relative">{children}</main>
            {showSampleAanvraag && (
                <Link href={"/akoestische-panelen/samples"} prefetch={false}>
                    <a
                        className={
                            "fixed bottom-5 left-5 bg-brand text-white drop-shadow px-3 py-2 flex flex-row uppercase transition-all duration-300 group hover:pl-5 hover:pr-1 items-center gap-x-4 z-50"
                        }
                    >
                        Samples aanvragen
                        <ArrowRight
                            size={18}
                            className="transition-all group-hover:ml-2 duration-300"
                        />
                    </a>
                </Link>
            )}
            <Footer />
        </div>
    )
}

export default Layout
