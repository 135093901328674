import { Popover, Transition } from "@headlessui/react"
import {
    useFeaturedProductsQuery,
    useNavigationCollections
} from "@lib/hooks/use-layout-data"
import repeat from "@lib/util/repeat"
import ProductPreview from "@modules/products/components/product-preview"
import SkeletonProductPreview from "@modules/skeletons/components/skeleton-product-preview"
import clsx from "clsx"
import { chunk } from "lodash"
import Link from "next/link"
import { useRouter } from "next/router"
import { Fragment, useState, FC } from "react"
import { FormattedMessage } from "react-intl"

const HeaderMenu: FC = () => {
    const [open, setOpen] = useState("")
    const { push } = useRouter()
    const { data: collections, isLoading: loadingCollections } =
        useNavigationCollections()
    const { data: products, isLoading: loadingProducts } =
        useFeaturedProductsQuery()

    return (
        <div className="flex items-end h-full items-center w-full md:w-auto justify-around md:justify-center">
            <Popover
                className="hidden md:flex text-xs md:text-lg"
                key={"menu-panelen"}
                onMouseEnter={() => setOpen("panelen")}
                onMouseLeave={() => setOpen("")}
            >
                <>
                    <Link href="/akoestische-panelen" passHref prefetch={false}>
                        <a
                            className={clsx(
                                "relative flex px-2 transition-all ease-out duration-500 ",
                                "hover:text-brand"
                            )}
                        >
                            <Popover.Button
                                className={clsx(
                                    "relative py-2 md:py-4 flex items-center transition-all ease-out duration-500"
                                )}
                                onClick={() => push("/akoestische-panelen")}
                            >
                                <FormattedMessage
                                    id={"main-menu-item"}
                                    defaultMessage={"Shop Akupanelen"}
                                />
                            </Popover.Button>
                        </a>
                    </Link>
                    <Transition
                        show={open === "panelen"}
                        as={Fragment}
                        enter="transition transform origin-top ease-in-out duration-300"
                        enterFrom="scale-y-0"
                        enterTo="scale-y-100"
                        leave="transition ease-in transform origin-top duration-200"
                        leaveFrom="scale-y-100"
                        leaveTo="scale-y-0"
                    >
                        <Popover.Panel
                            static
                            className="absolute top-full inset-x-0 text-base text-gray-700 z-30 border-y border-gray-200A"
                        >
                            <div className="relative bg-white py-8">
                                <div className="flex items-start content-container">
                                    <div className="flex flex-col flex-1 max-w-[30%]">
                                        <Link
                                            href="/akoestische-panelen"
                                            prefetch={false}
                                        >
                                            <h3 className="text-lg font-bold text-gray-900 mb-4 cursor-pointer">
                                                <FormattedMessage
                                                    id={"collections-title"}
                                                    defaultMessage={
                                                        "Akoestische panelen"
                                                    }
                                                />
                                            </h3>
                                        </Link>
                                        <div className="flex items-start text-lg">
                                            {collections &&
                                                chunk(collections, 6).map(
                                                    (chunk, index) => {
                                                        return (
                                                            <ul
                                                                key={index}
                                                                className="min-w-[152px] max-w-[200px] pr-4"
                                                            >
                                                                {chunk.map(
                                                                    (
                                                                        collection
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    collection.id
                                                                                }
                                                                                className="pb-3"
                                                                            >
                                                                                <Link
                                                                                    href={`/akoestische-panelen/${collection.handle}`}
                                                                                    prefetch={
                                                                                        false
                                                                                    }
                                                                                >
                                                                                    <a
                                                                                        onClick={() =>
                                                                                            setOpen(
                                                                                                ""
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            collection.title
                                                                                        }
                                                                                    </a>
                                                                                </Link>
                                                                            </div>
                                                                        )
                                                                    }
                                                                )}
                                                            </ul>
                                                        )
                                                    }
                                                )}
                                            {loadingCollections &&
                                                repeat(6).map((index) => (
                                                    <div
                                                        key={index}
                                                        className="w-12 h-4 bg-gray-100 animate-pulse"
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <div className="grid grid-cols-3 gap-4">
                                            {products
                                                ?.slice(0, 3)
                                                .map((product) => (
                                                    <ProductPreview
                                                        {...product}
                                                        key={product.id}
                                                    />
                                                ))}
                                            {loadingProducts &&
                                                repeat(3).map((index) => (
                                                    <SkeletonProductPreview
                                                        key={index}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            </Popover>

            <Link href="/akoestische-panelen" passHref prefetch={false}>
                <a
                    className={clsx(
                        "relative flex px-2 transition-all ease-out duration-500 ",
                        "hover:text-brand relative py-2 md:py-4 items-center text-xs md:text-lg flex md:hidden"
                    )}
                >
                    <FormattedMessage
                        id={"main-menu-akoestische-panelen"}
                        defaultMessage={"Shop Akupanelen"}
                    />
                </a>
            </Link>
            <Link href="/akoestische-panelen/samples" passHref prefetch={false}>
                <a
                    className={clsx(
                        "relative flex px-2 transition-all ease-out duration-500 ",
                        "hover:text-brand relative py-2 md:py-4 flex items-center text-xs md:text-lg"
                    )}
                >
                    <FormattedMessage
                        id={"main-menu-samples"}
                        defaultMessage={"Samples"}
                    />
                </a>
            </Link>
            <Link href="/zakelijk" passHref prefetch={false}>
                <a
                    className={clsx(
                        "relative flex px-2 transition-all ease-out duration-500 ",
                        "hover:text-brand relative py-2 md:py-4 flex items-center text-xs md:text-lg"
                    )}
                >
                    <FormattedMessage
                        id={"main-menu-zakelijk"}
                        defaultMessage={"Zakelijk bestellen"}
                    />
                </a>
            </Link>
        </div>
    )
}

export default HeaderMenu
